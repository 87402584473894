import EasyImgOne from "../assets/img/easy-portal.svg";
import EasyImgTwo from "../assets/img/easy-farm.svg";
import EasyImgThree from "../assets/img/easy-data.svg";
import Web3ImgOne from "../assets/img/web3-icon-1.svg";
import Web3ImgTwo from "../assets/img/web3-icon-2.svg";
import Web3ImgThree from "../assets/img/web3-icon-3.svg";
import Web3ImgFour from "../assets/img/web3-icon-4.svg";
import Web3ImgFive from "../assets/img/web3-icon-5.svg";
import EvmOne from "../assets/img/chain_icon1.png";
import EvmTwo from "../assets/img/chain_icon2.png";
import EvmThree from "../assets/img/chain_icon3.png";
import EvmFour from "../assets/img/chain_icon4.png";
import EvmFive from "../assets/img/chain_icon5.png";
import EvmSix from "../assets/img/chain_icon6.png";
import EvmSeven from "../assets/img/chain_icon7.png";
import EvmEight from "../assets/img/chain_icon8.png";

export const easytouse = [
  {
    imgUrl: EasyImgTwo,
    heading: "Gasless",
    para: "Gasless swaps and zaps with no cost for failed trainsactions means you never have to worry about gas or stuck transactions again.",
  },
  {
    imgUrl: EasyImgThree,
    heading: "Multichain",
    para: "Multichain support for for the most popular and newest EVM chains, with frequent additions",
  },
  {
    imgUrl: EasyImgOne,
    heading: "Zaps",
    para: "Bundle multi-step actions into a single multicall transaction that seamlessly swaps interest bearing, liquidity pool, vault, or any kind of token for any other",
  },
];
export const headerData = [
  // {
  //   link: "Home",
  //   pathname: "#home",
  // },
  {
    link: "Developers",
    pathname: "https://docs.portals.fi",
  },
  // {
  //   link: "Integrations",
  //   pathname: "#integrations",
  // },
  {
    link: "App",
    pathname: "https://app.portals.fi",
  },
];
export const web3Data = [
  {
    name: "SushiSwap",
    platform: "sushiswap",
    image:
      "https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png",
    network: ["ethereum", "avalanche", "polygon", "arbitrum"],
  },
  {
    name: "Uniswap",
    platform: "uniswapv2",
    image:
      "https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png",
    network: ["ethereum"],
  },
  {
    name: "Curve",
    platform: "curve",
    image: "https://assets.coingecko.com/coins/images/12124/large/Curve.png",
    network: [
      "ethereum",
      "avalanche",
      "polygon",
      "fantom",
      "arbitrum",
      "optimism",
    ],
  },
  {
    name: "Yearn Finance",
    platform: "yearn",
    image:
      "https://assets.coingecko.com/coins/images/11849/large/yfi-192x192.png",
    network: ["ethereum", "fantom", "arbitrum", "optimism"],
  },
  {
    name: "Yearn CRV",
    platform: "yearncrv",
    image:
      "https://assets.coingecko.com/coins/images/27622/large/yearncrvnew_32.png",
    network: ["ethereum"],
  },
  {
    name: "Aave V2",
    platform: "aavev2",
    image: "https://assets.coingecko.com/coins/images/12645/large/AAVE.png",
    network: ["ethereum", "avalanche", "polygon"],
  },
  {
    name: "Aave v3",
    platform: "aavev3",
    image: "https://assets.coingecko.com/coins/images/12645/large/AAVE.png",
    network: [
      "ethereum",
      "avalanche",
      "polygon",
      "fantom",
      "arbitrum",
      "optimism",
    ],
  },
  {
    name: "Compound",
    platform: "compound",
    image: "https://assets.coingecko.com/coins/images/10775/large/COMP.png",
    network: ["ethereum"],
  },
  {
    name: "Convex",
    platform: "convex",
    image: "https://assets.coingecko.com/coins/images/15585/large/convex.png",
    network: ["ethereum"],
  },
  {
    name: "IronBank",
    platform: "ironbank",
    image: "https://assets.coingecko.com/coins/images/22902/large/ironbank.png",
    network: ["ethereum", "avalanche", "fantom", "optimism"],
  },
  {
    name: "Stargate",
    platform: "stargate",
    image: "https://assets.coingecko.com/coins/images/24413/large/STG_LOGO.png",
    network: ["ethereum", "avalanche", "polygon", "bsc", "fantom", "optimism"],
  },
  {
    name: "Balancer V2",
    platform: "balancerv2",
    image:
      "https://assets.coingecko.com/coins/images/11683/large/Balancer.png?",
    network: ["ethereum", "polygon", "arbitrum"],
  },
  {
    name: "Balancer V2 Boosted Pools",
    platform: "balancerv2boosted",
    image:
      "https://assets.coingecko.com/coins/images/11683/large/Balancer.png?",
    network: ["ethereum"],
  },
  {
    name: "The Granary",
    platform: "thegranary",
    image: "https://granary.finance/static/media/GranaryLogo.6842f821.png",
    network: ["ethereum", "avalanche", "fantom", "optimism"],
  },
  {
    name: "ApeSwap",
    platform: "apeswap",
    image: "https://assets.coingecko.com/coins/images/14870/large/banana.png",
    network: ["ethereum", "polygon", "bsc"],
  },
  {
    name: "ShibaSwap",
    platform: "shibaswap",
    image: "https://shibaswap.com/static/media/shibaswap-icon.ee749b42.png",
    network: ["ethereum"],
  },
  {
    name: "PoolTogether",
    platform: "pooltogether",
    image:
      "https://assets.coingecko.com/coins/images/14003/large/PoolTogether.png",
    network: ["ethereum", "avalanche", "polygon", "optimism"],
  },
  {
    name: "StakeDAO",
    platform: "stakedao",
    image:
      "https://assets.coingecko.com/coins/images/13724/large/stakedao_logo.jpg",
    network: ["ethereum"],
  },
  {
    name: "StakeDAO Vaults",
    platform: "stakedao-vaults",
    image:
      "https://assets.coingecko.com/coins/images/13724/large/stakedao_logo.jpg",
    network: ["ethereum"],
  },
  {
    name: "Beefy",
    platform: "beefy",
    image: "https://assets.coingecko.com/coins/images/12704/large/token.png",
    network: ["ethereum", "avalanche", "polygon", "arbitrum", "optimism"],
  },
  {
    name: "Verse",
    platform: "verse",
    image:
      "https://assets.coingecko.com/coins/images/28424/large/verselogo.png",
    network: ["ethereum"],
  },
  {
    name: "FRAX Swap",
    platform: "fraxswap",
    image:
      "https://assets.coingecko.com/coins/images/13422/large/FRAX_icon.png",
    network: ["ethereum", "polygon"],
  },
  {
    name: "Flux Finance",
    platform: "fluxfinance",
    image: "https://icons.llamao.fi/icons/protocols/flux-finance?w=50&h=50",
    network: ["ethereum"],
  },
  {
    name: "Uwulend",
    platform: "uwulend",
    image:
      "https://assets.coingecko.com/coins/images/27408/large/uwu_logo_no-bg_1024.png",
    network: ["ethereum"],
  },
  {
    name: "Vesper",
    platform: "vesper",
    image:
      "https://assets.coingecko.com/coins/images/13527/large/vesper_logo.jpg",
    network: ["ethereum", "optimism"],
  },
  {
    name: "Pangolin",
    platform: "pangolin",
    image:
      "https://assets.coingecko.com/coins/images/14023/large/mXGnm3Eo_400x400.jpg",
    network: ["avalanche"],
  },
  {
    name: "TraderJoe",
    platform: "traderjoe",
    image:
      "https://assets.coingecko.com/coins/images/17569/large/joe_200x200.png",
    network: ["avalanche", "arbitrum"],
  },
  {
    name: "BankerJoe",
    platform: "bankerjoe",
    image:
      "https://assets.coingecko.com/coins/images/17569/large/joe_200x200.png",
    network: ["avalanche"],
  },
  {
    name: "Benqi",
    platform: "benqi",
    image:
      "https://assets.coingecko.com/coins/images/16362/large/GergDDN3_400x400.jpg",
    network: ["avalanche"],
  },
  {
    name: "Quickswap",
    platform: "quickswap",
    image:
      "https://assets.coingecko.com/coins/images/25393/large/quickswap.jpg",
    network: ["polygon"],
  },
  {
    name: "MM Finance",
    platform: "mmfinance",
    image:
      "https://assets.coingecko.com/coins/images/22273/large/MMF200X200.png",
    network: ["polygon"],
  },
  {
    name: "PancakeSwap",
    platform: "pancakeswap",
    image:
      "https://assets.coingecko.com/coins/images/12632/large/pancakeswap-cake-logo_%281%29.png",
    network: ["bsc"],
  },
  {
    name: "Venus",
    platform: "venus",
    image: "https://assets.coingecko.com/coins/images/12677/large/download.jpg",
    network: ["bsc"],
  },
  {
    name: "Biswap",
    platform: "biswap",
    image: "https://assets.coingecko.com/coins/images/16845/large/biswap.png",
    network: ["bsc"],
  },
  {
    platform: "apeswap-lending",
    image: "https://assets.coingecko.com/coins/images/14870/large/banana.png",
    network: ["bsc"],
    name: "ApeSwap Lending",
  },
  {
    platform: "nomiswap",
    image:
      "https://assets.coingecko.com/coins/images/14424/large/NMX_c_200px.png",
    network: ["bsc"],
    name: "NomiSwap",
  },
  {
    platform: "mdex",
    image: "https://assets.coingecko.com/coins/images/13775/large/mdex.png",
    network: ["bsc"],
    name: "Mdex",
  },
  {
    platform: "babydogeswap",
    image: "https://assets.coingecko.com/coins/images/16125/large/babydoge.jpg",
    network: ["bsc"],
    name: "BabyDogeSwap",
  },
  {
    platform: "radiantv2",
    image:
      "https://assets.coingecko.com/coins/images/26536/large/Radiant-Logo-200x200.png",
    network: ["bsc", "arbitrum"],
    name: "Radiant v2",
  },
  {
    platform: "spiritswap",
    image:
      "https://assets.coingecko.com/coins/images/15118/large/4mY2Biy9_400x400.jpg",
    network: ["fantom"],
    name: "SpiritSwap",
  },
  {
    platform: "spookyswap",
    image:
      "https://assets.coingecko.com/coins/images/15223/large/logo_200x200.png",
    network: ["fantom"],
    name: "SpookySwap",
  },
  {
    platform: "scream",
    image:
      "https://assets.coingecko.com/coins/images/17509/large/output-onlinepngtools_%286%29.png",
    network: ["fantom"],
    name: "Scream",
  },
  {
    platform: "geist",
    image:
      "https://assets.coingecko.com/coins/images/18832/large/GEIST-token.png",
    network: ["fantom"],
    name: "Geist",
  },
  {
    platform: "beethovenx",
    image:
      "https://assets.coingecko.com/coins/images/19158/large/beets-icon-large.png",
    network: ["fantom", "optimism"],
    name: "Beethoven X",
  },
  {
    platform: "beethovenx",
    image:
      "https://assets.coingecko.com/coins/images/19158/large/beets-icon-large.png",
    network: ["fantom", "optimism"],
    name: "Beethoven X Boosted Pools",
  },
  {
    platform: "arbitrumexchange",
    image:
      "https://assets.coingecko.com/coins/images/29506/large/tokenlogo.png",
    network: ["arbitrum"],
    name: "Arbitrum Exchange",
  },
  {
    platform: "camelotv2",
    image:
      "https://assets.coingecko.com/coins/images/28416/large/vj5DIMhP_400x400.jpeg",
    network: ["arbitrum"],
    name: "Camelot V2",
  },
  {
    platform: "dfynv1",
    image:
      "https://assets.coingecko.com/coins/images/15368/large/SgqhfWz4_400x400_%281%29.jpg",
    network: ["polygon"],
    name: "Dfyn V1",
  },
  {
    platform: "baseswap",
    image:
      "https://assets.coingecko.com/coins/images/31245/large/Baseswap_LogoNew.jpg",
    network: ["base"],
    name: "BaseSwap",
  },
  {
    platform: "synthswap",
    image: "https://assets.coingecko.com/coins/images/31190/large/synth.png",
    network: ["base"],
    name: "Synthswap",
  },
  {
    platform: "rocketswap",
    image: "https://icons.llamao.fi/icons/protocols/rocketswap-base",
    network: ["base"],
    name: "RocketSwap",
  },
  {
    platform: "swapbased",
    image: "https://icons.llamao.fi/icons/protocols/swapbased",
    network: ["base"],
    name: "SwapBased",
  },
  {
    platform: "swirlend",
    image: "https://icons.llamao.fi/icons/protocols/swirllend",
    network: ["base"],
    name: "SwirLend",
  },
];

export const evmChainData = [
  {
    imgUrl: EvmOne,
    externalUrl: "https://ethereum.org",
  },
  {
    imgUrl: EvmTwo,
    externalUrl: "https://www.avax.network/",
  },
  {
    imgUrl: EvmThree,
    externalUrl: "https://fantom.foundation/",
  },
  {
    imgUrl: EvmFour,
    externalUrl: "https://polygon.technology/",
  },
  {
    imgUrl: EvmFive,
    externalUrl: "https://www.optimism.io/",
  },
  {
    imgUrl: EvmSix,
    externalUrl: "https://www.bnbchain.org/",
  },
  {
    imgUrl: EvmSeven,
    externalUrl: "https://arbitrum.io/",
  },
  {
    imgUrl: EvmEight,
    externalUrl: "https://base.org/",
  },
];
