import "./App.css";
import Hero from "./components/Hero";
import EasyToUse from "./components/EasyToUse";
import DefiApi from "./components/DefiApi";
import EVMchains from "./components/EVMchains";
import PortalApi from "./components/PortalApi";
import Web3 from "./components/Web3";
import AboutPortal from "./components/AboutPortal";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import BackToTop from "./components/BackToTop";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  // AOS
  AOS.init({
    once: true,
  });
  return (
    <div>
      <Hero />
      <EasyToUse />
      <EVMchains />
      <DefiApi />
      <PortalApi />
      <Web3 />
      {/* <AboutPortal /> */}
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
