import React from "react";
import { evmChainData } from "./Helper";

const EVMchains = () => {
  return (
    <>
      <section className="evm_bg py-5" id="app">
        <div className="container pt-sm-4 pb-md-5">
          <div className="row">
            <div
              className="col-lg-5 text-center m-auto"
              data-aos="fade-down"
              data-aos-duration="500"
            >
              <h3 className="font-2xl fw-600 color-white ff-poppins">
                With support for the most popular
                <span className="emphasis_color fw-500"> EVM chains</span>
              </h3>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            {evmChainData.map((val, index) => {
              return (
                <div
                  className="col-3 col-sm-2 col-lg-1 mt-3"
                  key={index}
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay={`${(index + 1) * 100}`}
                >
                  <a href={val.externalUrl} target="_blank" rel="noreferrer">
                    <img
                      className="evm_img cursor_pointer"
                      src={val.imgUrl}
                      alt="evm chain"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default EVMchains;
