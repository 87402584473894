import React from "react";
import ShadowOne from "../assets/img/defi_shadow.svg";
import BtnArrow from "../assets/img/btn-arrow.png";
import Typewriter from "typewriter-effect";

const DefiApi = () => {
  return (
    <>
      <section
        className="position-relative defi_api_bg
overflow_hidden"
      >
        <div className="position-absolute defi_shadow_one d-none d-sm-block">
          <img src={ShadowOne} alt="DefiImg" />
        </div>
        <div className="container pt-xl-5 pb-5 pb-xl-0">
          <div className="row pb-md-5 pb-lg-0">
            <div
              className="
              col-12 col-md-10 col-lg-6
              mx-auto
              mb-5 mb-lg-0
              "
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <div className="defi_img_card position-relative">
                <div className="position-absolute top-0 w-100">
                  <div className="black_strip">
                    <span className="dots ms-3"></span>
                    <span className="dots ms-3"></span>
                    <span className="dots ms-3"></span>
                  </div>
                </div>
                <div className="text-white text_card">
                  <Typewriter
                    options={{ cursor: "" }}
                    onInit={(typewriter) =>
                      typewriter
                        .changeDelay(10)
                        .typeString(
                          '<span class=d-block>const route = "<a class=defi_card_link href=https://api.portals.fi/v2/portal target=_blank>https://api.portals.fi/v2/portal</a>";</span> <span class=d-block>const params = {</span><span class=text_block>     sender: userAddress,</span><span class=text_block> inputToken: yearnDaiVault,</span><span class=text_block> outputToken: curve3Pool,</span> <span class=text_block>inputAmount,</span><span class=d-block>};</span><span class=d-block>const { data: { tx } } = await axios.get(route, {params} );</span><span class=d-block>return tx;</span>'
                        )
                        .start()
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="
              col-8 col-sm-10 col-12 col-lg-5
              mx-auto
              z-index-1 "
              data-aos="fade-left"
              data-aos-duration="500"
            >
              <div className="pe-xxl-5 me-3 mt-lg-4">
                <h4 className="main-heading  mt-xl-5 mt-xxl-0">
                  The <span className="emphasis_color">DeFi API</span> for
                  builders
                </h4>
                <p className="main-para ">
                  Portals can be easily integrated by builders in a few lines of
                  code, enabling anyone to condense multi-step DeFi transactions
                  into a single step with a simple API request.
                </p>
                <div className="mt-xl-4">
                  <a
                    href="https://docs.portals.fi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="common_btn defi_btn mt-3">
                      Start Building
                      <span className="ms-2 ps-1">
                        <img
                          className="btn_arrow_img"
                          src={BtnArrow}
                          alt="btn-arrow-icon"
                        />
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DefiApi;
