import React from "react";
import BtnArrow from "../assets/img/btn-arrow.png";
import ApiImg from "../assets/img/api-img.svg";
const PortalApi = () => {
  return (
    <>
      <section className="pt-5 py-sm-5 overflow_hidden">
        <div className="container container-modified py-lg-5">
          <div className="row justify-content-between">
            <div
              className="col-lg-5 d-flex justify-content-center flex-column order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <h3 className="main-heading mt-3 mt-lg-0">
                Empower your dApp with the{" "}
                <span className="emphasis_color"> Portals API</span>
              </h3>
              <p className="main-para">
                <p>
                  Get data about all of our supported opportunities, including
                  asset prices, liquidity data, account balances, APYs, token
                  images, contract addresses, and more.
                </p>
              </p>
              <div className="mt-1">
                <a
                  href="https://docs.portals.fi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="common_btn">
                    Developer Docs
                    <span className="ms-2 ps-1">
                      <img
                        className="btn_arrow_img"
                        src={BtnArrow}
                        alt="btn-arrow"
                      />
                    </span>
                  </button>
                </a>
              </div>
            </div>
            <div
              className="col-sm-10 col-lg-6 order-1 order-lg-2 m-auto"
              data-aos="fade-left"
              data-aos-duration="500"
            >
              <img width="100%" src={ApiImg} alt="api-img" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortalApi;
