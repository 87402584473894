export const Twitter = () => {
  return (
    <>
      <svg
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6788 1.53747C16.0801 1.80353 15.4449 1.97835 14.7944 2.05609C15.4798 1.64477 15.993 0.998985 16.2391 0.238268C15.5934 0.621806 14.8865 0.891246 14.1494 1.03478C13.6991 0.555349 13.1151 0.222336 12.4733 0.0789344C11.8315 -0.0644674 11.1614 -0.0116387 10.55 0.230564C9.9385 0.472767 9.41391 0.893168 9.04422 1.43725C8.67452 1.98132 8.47678 2.62397 8.47665 3.28184C8.47698 3.53346 8.50517 3.78427 8.5607 4.02968C7.25553 3.96465 5.97862 3.62571 4.81292 3.03488C3.64722 2.44405 2.61879 1.61455 1.79442 0.60024C1.37442 1.32249 1.24556 2.17778 1.43411 2.99179C1.62267 3.80579 2.11444 4.51722 2.80919 4.98108C2.28886 4.96601 1.77987 4.82543 1.32553 4.57131V4.61114C1.32589 5.3687 1.58749 6.10296 2.06618 6.68999C2.54486 7.27703 3.21133 7.68091 3.95312 7.83348C3.67129 7.90767 3.38106 7.94515 3.08964 7.945C2.88186 7.94527 2.67452 7.92601 2.47034 7.88747C2.68084 8.53927 3.08923 9.10922 3.63867 9.518C4.18811 9.92677 4.85127 10.154 5.53587 10.1682C4.37225 11.079 2.93663 11.5727 1.45912 11.57C1.19869 11.5697 0.93848 11.5549 0.679688 11.5258C2.09014 12.4293 3.71763 12.9366 5.39151 12.9944C7.06539 13.0523 8.724 12.6585 10.1934 11.8544C11.6628 11.0504 12.8889 9.86565 13.7431 8.42447C14.5973 6.98328 15.0481 5.33874 15.0483 3.66328L15.0386 3.23848C15.6832 2.77495 16.2391 2.19905 16.6797 1.53836L16.6788 1.53747Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export const Discord = () => {
  return (
    <>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3226 0.997273C12.3254 0.532395 11.2457 0.194983 10.121 3.44896e-05C10.1111 -0.00028088 10.1013 0.0015697 10.0922 0.00545614C10.0832 0.00934258 10.0751 0.0151705 10.0685 0.0225288C9.93352 0.269964 9.77606 0.592379 9.67109 0.839815C8.47815 0.659862 7.26497 0.659862 6.07203 0.839815C5.96706 0.584881 5.8096 0.269964 5.66714 0.0225288C5.65964 0.00753268 5.63715 3.44896e-05 5.61465 3.44896e-05C4.48995 0.194983 3.41773 0.532395 2.41299 0.997273C2.40549 0.997273 2.398 1.00477 2.3905 1.01227C0.351031 4.06397 -0.211322 7.03319 0.0661058 9.97242C0.0661058 9.98742 0.0736038 10.0024 0.0885999 10.0099C1.43825 10.9997 2.73541 11.5995 4.01757 11.9969C4.04007 12.0044 4.06256 11.9969 4.07006 11.9819C4.36998 11.5695 4.63991 11.1346 4.87235 10.6772C4.88734 10.6472 4.87235 10.6173 4.84236 10.6098C4.41497 10.4448 4.01007 10.2499 3.61268 10.0249C3.58269 10.0099 3.58269 9.96493 3.60518 9.94243C3.68766 9.88245 3.77014 9.81497 3.85261 9.75498C3.86761 9.73998 3.8901 9.73998 3.9051 9.74748C6.48443 10.9247 9.2662 10.9247 11.8155 9.74748C11.8305 9.73998 11.853 9.73998 11.868 9.75498C11.9505 9.82246 12.033 9.88245 12.1155 9.94993C12.1454 9.97242 12.1454 10.0174 12.108 10.0324C11.7181 10.2648 11.3057 10.4523 10.8783 10.6173C10.8483 10.6248 10.8408 10.6622 10.8483 10.6847C11.0882 11.1421 11.3582 11.577 11.6506 11.9894C11.6731 11.9969 11.6956 12.0044 11.7181 11.9969C13.0077 11.5995 14.3049 10.9997 15.6545 10.0099C15.6695 10.0024 15.677 9.98742 15.677 9.97242C16.0069 6.57581 15.1297 3.62908 13.3526 1.01227C13.3451 1.00477 13.3376 0.997273 13.3226 0.997273ZM5.26225 8.18039C4.48995 8.18039 3.84512 7.46808 3.84512 6.59081C3.84512 5.71354 4.47495 5.00122 5.26225 5.00122C6.05704 5.00122 6.68687 5.72104 6.67937 6.59081C6.67937 7.46808 6.04954 8.18039 5.26225 8.18039ZM10.4884 8.18039C9.71608 8.18039 9.07125 7.46808 9.07125 6.59081C9.07125 5.71354 9.70108 5.00122 10.4884 5.00122C11.2832 5.00122 11.913 5.72104 11.9055 6.59081C11.9055 7.46808 11.2832 8.18039 10.4884 8.18039Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export const Github = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.56438 0.230657C4.08953 0.408555 4.58912 0.654471 5.05041 0.962132C5.70187 0.795633 6.37173 0.711989 7.04413 0.713179C7.73854 0.713179 8.40847 0.799893 9.03645 0.961433C9.49755 0.654071 9.9969 0.408392 10.5218 0.230657C11.0092 0.0649216 11.7036 -0.203612 12.1162 0.253035C12.3959 0.563527 12.4658 1.08381 12.5155 1.48102C12.5714 1.92438 12.5847 2.502 12.4379 3.07544C12.9994 3.80062 13.3379 4.66566 13.3379 5.60832C13.3379 7.03631 12.5645 8.27618 11.4197 9.13493C10.8688 9.54299 10.2577 9.8629 9.60848 10.0832C9.75813 10.4258 9.84135 10.8049 9.84135 11.2028V13.3007C9.84135 13.4862 9.76768 13.664 9.63653 13.7952C9.50539 13.9263 9.32751 14 9.14205 14H4.94621C4.76074 14 4.58287 13.9263 4.45172 13.7952C4.32058 13.664 4.2469 13.4862 4.2469 13.3007V12.6077C3.57906 12.6895 3.01892 12.6168 2.54269 12.4147C2.04479 12.2035 1.69793 11.8762 1.43709 11.5629C1.18953 11.2664 0.919602 10.5979 0.529389 10.4678C0.442239 10.4388 0.361657 10.3929 0.292246 10.3327C0.222834 10.2726 0.165952 10.1993 0.124847 10.1172C0.0418324 9.95127 0.0281206 9.75919 0.0867284 9.58318C0.145336 9.40717 0.271462 9.26165 0.437361 9.17864C0.60326 9.09562 0.795342 9.08191 0.971351 9.14052C1.43709 9.29577 1.74059 9.63143 1.94828 9.90137C2.28395 10.3349 2.55668 10.9014 3.08815 11.1273C3.30703 11.2203 3.62802 11.2811 4.13012 11.2126L4.2469 11.1888C4.24824 10.8082 4.32749 10.432 4.47977 10.0832C3.83053 9.86291 3.21949 9.543 2.66857 9.13493C1.5238 8.27618 0.75037 7.03701 0.75037 5.60832C0.75037 4.66706 1.08814 3.80271 1.64828 3.07823C1.50143 2.5048 1.51401 1.92578 1.56996 1.48172L1.57345 1.45514C1.6245 1.04815 1.68394 0.569122 1.96926 0.253035C2.38185 -0.203612 3.07696 0.0656211 3.56368 0.231357L3.56438 0.230657Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export const SwitchBtn = () => {
  return (
    <>
      <svg
        width="21"
        height="51"
        viewBox="0 0 21 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.49658"
          y="1.09277"
          width="19"
          height="49"
          rx="9.5"
          stroke="white"
        />
        <rect
          x="8.99658"
          y="12.1807"
          width="4"
          height="10"
          rx="2"
          fill="white"
        />
      </svg>
    </>
  );
};
