import React from "react";
import logo from "../assets/img/logo.svg";
import { Twitter, Discord, Github } from "./Icons";

const BADGE_ID = "zM0OTQ5NzkzNTIyN";

let ALCHEMY_URL = `https://alchemyapi.io/?r=badge:${BADGE_ID}`;
const ALCHEMY_ANALYTICS_URL = `https://analytics.alchemyapi.io/analytics`;

function logBadgeClick() {
  fetch(`${ALCHEMY_ANALYTICS_URL}/badge-click`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      badge_id: BADGE_ID,
    }),
  });
  window.open(ALCHEMY_URL, "_blank").focus();
}

function logBadgeView() {
  fetch(`${ALCHEMY_ANALYTICS_URL}/badge-view`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      badge_id: BADGE_ID,
    }),
  });
}

function isBadgeInViewpoint(bounding) {
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

var intervalId = setInterval(() => {
  const badge = document.getElementById("badge-button");
  if (badge && isBadgeInViewpoint(badge.getBoundingClientRect())) {
    logBadgeView();
    clearInterval(intervalId);
  }
}, 2000);

const Footer = () => {
  const getCurrentYear = new Date().getFullYear();
  return (
    <footer className="footer_bg pt-5 text-white">
      <div className="container ps-1 ps-sm-auto container-modified pb-5 pt-sm-4">
        <div className="row justify-content-between">
          <div className="col-md-4">
            <div>
              <img
                width="100%"
                className="footer_logo me-5"
                src={logo}
                alt="logo"
              />
            </div>
            <p className="footer_para pt-2 pb-3 pb-lg-0 pe-xxl-5 me-xl-5 ps-3 ff-poppins">
              {/* Footer text */}
            </p>
          </div>
          <div className="col-md-7 col-xxl-6">
            <div className="d-flex justify-content-between">
              {/* <ul className="list-unstyled ps-3 ps-lg-0">
                <p className="footer_heading ff-poppins">About</p>
                <li className="footer_links opacity-07 py-1 ff-poppins cursor_pointer">
                  Blog
                </li>
                <li className="footer_links opacity-07 py-1 ff-poppins cursor_pointer">
                  Jobs
                </li>
                <li className="footer_links opacity-07 py-1 ff-poppins cursor_pointer">
                  Privacy Policy
                </li>
                <li className="footer_links opacity-07 py-1 ff-poppins cursor_pointer">
                  Terms of Service
                </li>
              </ul> */}
              <ul className="list-unstyled">
                <p className="footer_heading ff-poppins">Developers</p>
                <li className="footer_links py-1 ff-poppins">
                  <a
                    href="https://docs.portals.fi"
                    className="opacity-07 text-decoration-none footer_links ff-poppins"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Documentation
                  </a>
                </li>
                <li className="footer_links py-1 ff-poppins">
                  <a
                    className="cursor_pointer opacity-07 text-decoration-none footer_links ff-poppins"
                    href="https://github.com/portals-fi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GitHub
                  </a>
                </li>
                <li className="footer_links  py-1 ff-poppins">
                  <a
                    className="cursor_pointer  text-decoration-none footer_links ff-poppins"
                    href="https://ethereum.stackexchange.com/questions/tagged/portals"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stack Exchange
                  </a>
                </li>
                <li className="footer_links  py-1 ff-poppins">
                  <a
                    className="cursor_pointer  text-decoration-none footer_links ff-poppins"
                    href="https://contact.portals.fi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
              <ul className="list-unstyled ps-2 ps-sm-0">
                <p className="footer_heading ff-poppins">Community</p>
                <li className="footer_links  py-1 ff-poppins">
                  <a
                    className=" text-decoration-none footer_links ff-poppins"
                    href="https://twitter.com/portals_fi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li className="footer_links py-1 ff-poppins">
                  <a
                    className="opacity-07 text-decoration-none footer_links ff-poppins"
                    href="https://discord.gg/YD7RRMu76s"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Discord
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="border_top_custom py-3">
        <div className="container  container-modified ">
          <div className="row justify-content-between">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="col-xl-2 col-6"
            >
              <div style={{ display: "inline-block" }}>
                <p className="opacity-07">
                  Copyright <span>{getCurrentYear}</span>, Portals.fi
                </p>
              </div>
              <div className="col-md-3 col-6 social_icons d-flex justify-content-end">
                <a
                  href="https://twitter.com/portals_fi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter />
                </a>
                <a
                  href="https://discord.gg/YD7RRMu76s"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Discord />
                </a>
                <a
                  href="https://github.com/portals-fi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Github />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
