import swapDemoPng from "../assets/img/swap-demo.png";
import swapDemoWebm from "../assets/video/swap-demo.webm";
import swapDemoMp4 from "../assets/video/swap-demo.mp4";
import BtnArrow from "../assets/img/btn-arrow.png";
import gsap from "gsap/dist/gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useLayoutEffect } from "react";
import Header from "./Header";
import Typewriter from "typewriter-effect";

gsap.registerPlugin(ScrollTrigger);
const Hero = () => {
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let hero = gsap.timeline();
        hero
          .fromTo(
            ".header_animate",
            {
              opacity: 0,
              y: -100,
            },
            { y: 0, opacity: 1, ease: "back.out(1)" }
          )
          .fromTo(
            ".hero_heading",
            {
              autoAlpha: 0,
              xPercent: -100,
              duration: 0.5,
            },
            {
              xPercent: 0,
              autoAlpha: 1,
              ease: "back.out(1.2)",
            }
          )
          .fromTo(
            ".hero_para",
            {
              autoAlpha: 0,
              xPercent: -100,
              duration: 0.5,
            },
            {
              xPercent: 0,
              autoAlpha: 1,
              ease: "back.out(.5)",
            }
          )
          .fromTo(
            ".hero_btn",
            {
              autoAlpha: 0,
              xPercent: -100,
              duration: 2,
            },
            {
              xPercent: 0,
              autoAlpha: 1,
              ease: "back.out(.5)",
            }
          )
          .fromTo(
            ".hero-section-image",
            {
              opacity: 0,
              scale: 0.5,
            },
            {
              scale: 1,
              opacity: 1,
              ease: "back.out(1.4)",
            }
          )

          .fromTo(
            ".switch_btn",
            {
              autoAlpha: 0,
              bottom: "-200px",
            },
            {
              autoAlpha: 1,
              bottom: "30px",
              ease: "back.out(1.4)",
            },
            "=-.5"
          );
      },
    });
  }, []);

  return (
    <>
      <div
        className="hero_bg"
        style={{
          overflow: "hidden",
        }}
      >
        <div
          className="header_animate"
          style={{
            height: "60px",
          }}
        >
          <Header />
        </div>
        <div
          className="container container-modified min_height_100vh d-flex flex-column justify-content-center position-relative"
          id="home"
        >
          <div className="row text-center text-lg-start justify-content-center justify-content-lg-start gy-3">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pe-xl-3 pb-sm-5 pt-4 pt-sm-5
            "
            >
              <h1
                className="hero_heading"
                style={{
                  minHeight: "85px",
                }}
              >
                One click access to
                <br className="d-xxl-none d-none d-xl-block" />
                <span
                  className="d-inline-block ps-2 ps-lg-0 ps-xxl-3 pb-1"
                  style={{
                    color: "rgba(171, 122, 255, 1)",
                  }}
                >
                  <Typewriter
                    options={{
                      strings: [
                        "Vaults",
                        "Yields",
                        "Pools",
                        "Staking",
                        "Swaps",
                        "Zaps",
                      ],
                      autoStart: true,
                      loop: true,
                      delay: 25,
                      deleteSpeed: 10,
                    }}
                  />
                </span>
              </h1>
              <div className="pe-xxl-3">
                <p className="main-para pe-xl-5 me-xxl-5 hero_para">
                  Unleash the full potential of DeFi with Portals, experience it
                  like never before
                </p>
              </div>
              <div className="hero_btn">
                <a
                  href="https://docs.portals.fi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="common_btn  mt-3 me-3">
                    Build
                    <span className="ms-2 ps-1">
                      <img
                        className="btn_arrow_img"
                        src={BtnArrow}
                        alt="BtnArrow"
                      />
                    </span>
                  </button>
                </a>
                <a
                  href="https://app.portals.fi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="border_btn  mt-3">
                    Swap
                    <span className="ms-2 ps-1">
                      <img
                        className="btn_arrow_img"
                        src={BtnArrow}
                        alt="BtnArrow"
                      />
                    </span>
                  </button>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-sm-8 col-12 pb-5 position-relative">
              <video
                autoPlay
                playsInline
                loop
                muted
                controls={false}
                width="100%"
                className="hero-section-image"
                style={{
                  borderRadius: "12px",
                }}
                poster={swapDemoPng}
              >
                <source src={swapDemoMp4} type="video/mp4" />
                <source src={swapDemoWebm} type="video/webm" />
              </video>
            </div>
            <a
              href="#easy-to-use1"
              className="switch_btn p-0 position-absolute cursor_pointer"
            >
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
