import React from "react";
import { web3Data } from "./Helper";

const Web3 = () => {
  return (
    <>
      <section className="py-5 web3_bg" id="documentation">
        <div className="container container-modified pt-5 py-sm-5">
          <div
            className="row justify-content-center"
            style={{
              marginBottom: 16,
            }}
          >
            <div
              className="col-lg-7 text-center"
              data-aos="fade-down"
              data-aos-duration="500"
            >
              <h5 className="main-heading pe-lg-5">
                <span className="emphasis_color">Portals </span> are open across
                DeFi
              </h5>
              <p className="main-para">
                Become a liquidity provider, earn yield in vaults, lend your
                assets, swap any token, and more with our supported protocols
              </p>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gridColumnGap: "8px",
              gridRowGap: "8px",
            }}
          >
            {web3Data.map((val, index) => {
              return (
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay={`${(index + 1) * 50}`}
                  style={{
                    borderRadius: 10,
                    background: "#272A39",
                    boxShadow: "0px 4px 20px  0px rgba(0, 0, 0, 0.25)",
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={val.image}
                    alt={`${val.name} logo`}
                    style={{
                      width: "32px",
                      height: "32px",
                      marginRight: 16,
                      borderRadius: 8,
                    }}
                  />
                  <h4
                    style={{
                      color: "#fff",
                      fontSize: 16,
                      margin: 0,
                      fontWeight: "normal",
                    }}
                  >
                    {val.name}
                  </h4>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Web3;
