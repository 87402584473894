import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { headerData } from "./Helper";
import { Twitter, Discord, Github } from "./Icons";
import logo from "../assets/img/logo.svg";

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handldeOverlayActive = () => {
    document.body.classList.toggle("active-nav-overlay");
  };

  const mobileView = useMemo(
    () => (
      <>
        <div className="px-3 d-flex justify-content-between align-items-center py-xl-2 pt-2">
          <div className="nav-logo w_100">
            <a href="#home">
              <img width="100%" className="header_logo" src={logo} alt="logo" />
            </a>
          </div>
          <div className="d-xl-none w_100">
            <div className=" social_icons d-flex  justify-content-center">
              <a
                href="https://twitter.com/portals_fi"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
              <a
                href="https://discord.gg/YD7RRMu76s"
                target="_blank"
                rel="noreferrer"
              >
                <Discord />
              </a>
              <a
                href="https://github.com/portals-fi"
                target="_blank"
                rel="noreferrer"
              >
                <Github />
              </a>
            </div>
          </div>
          <div className="w_100 ">
            <div
              id="nav-icon1"
              className={`hamburger-menu ms-auto`}
              onClick={() => handldeOverlayActive()}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className={`navbar-overlay  `}>
          <ul className="h-100 list-unstyled d-flex flex-column align-items-center justify-content-center m-0">
            {headerData.map((obj, index) => (
              <li
                key={index}
                className="py-2"
                onClick={() => handldeOverlayActive()}
              >
                <a
                  className=" navbar-link text-decoration-none overlay_links text-nowrap"
                  href={obj.pathname}
                >
                  {obj.link}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </>
    ),
    []
  );

  const desktopView = useMemo(
    () => (
      <div className="container d-flex justify-content-between align-items-center container-modified">
        <div className="nav-logo  cursor_pointer py-2 ">
          <a href="/">
            <img width="100%" className="header_logo" src={logo} alt="logo" />
          </a>
        </div>

        <div className="d-flex">
          <div className="nav-list-items">
            <ul className="list-unstyled d-flex align-items-center m-0 px-4 justify-content-end">
              {headerData.map((obj, index) => (
                <li className="pe-3 me-1" key={index}>
                  <a
                    className="text-decoration-none navbar-link text-nowrap ff-poppins fw-400"
                    href={obj.pathname}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {obj.link}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <ul className="social-icons list-unstyled d-flex align-items-center mb-0 pe-4 me-2">
            <div className=" social_icons d-flex justify-content-end">
              <a
                href="https://twitter.com/portals_fi"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
              <a
                href="https://discord.gg/YD7RRMu76s"
                target="_blank"
                rel="noreferrer"
              >
                <Discord />
              </a>
              <a
                href="https://github.com/portals-fi"
                target="_blank"
                rel="noreferrer"
              >
                <Github />
              </a>
            </div>
          </ul>
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      <div className="navbar-wrapper w-100 z-5">
        {isMobile ? mobileView : desktopView}
      </div>
    </>
  );
};

export default Header;
