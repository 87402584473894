import React from "react";
import { easytouse } from "./Helper";

const EasyToUse = () => {
  return (
    <>
      <section className="easy_to_use_bg py-5" id="easy-to-use">
        <div
          className="container py-sm-5 container-modified my-lg-5"
          id="easy-to-use1"
        >
          <div className="row ">
            <div
              className="col-md-10 col-xl-7 m-sm-auto"
              data-aos="fade-down"
              data-aos-duration="500"
            >
              <div className="text-center">
                <h3 className={`main-heading`}>
                  Supercharge your Web3 experience with{" "}
                  <span className="emphasis_color">one click</span> access to
                  any asset
                </h3>
                <p className="main-para mt-3">
                  Tired of juggling multiple DeFi transactions? Simplify your
                  experience with Portals – bundle all your DeFi actions into
                  one <span className="emphasis_color">gasless</span>{" "}
                  transaction.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-3 mt-sm-4 justify-content-center">
            {easytouse.map((val, index) => {
              return (
                <div
                  className="col-11 col-sm-6 col-lg-4 mt-4"
                  key={index}
                  data-aos="zoom-in"
                  data-aos-duration="600"
                  data-aos-delay={`${(index + 1) * 300}`}
                >
                  <div className="text-center easy_to_use_cards h-100 d-flex flex-column">
                    <div>
                      <h2 className="font-xxl fw-600 color-white ff-poppins">
                        {val.heading}
                      </h2>
                      <p className="font-md fw-400 color-white opacity-07 ff-poppins">
                        {val.para}
                      </p>
                    </div>

                    <div
                      className="d-flex 
                      flex-column justify-content-center align-items-center flex-grow-1 py-2"
                    >
                      <img
                        className="cards_img"
                        src={val.imgUrl}
                        alt="easy-to-use"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default EasyToUse;
